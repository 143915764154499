<template lang="pug">
  v-card
    v-card-title
        | Upload video
        v-spacer
            v-icon(aria-label="Close" @click="$emit('close')")
                | mdi-close
    v-card-text
        v-row
            v-col(cols="12" md="12")
                v-col(cols="12" md="12")
                  formStepper(:model ='model', :onSave='updateUpload' :update='true')
</template>
<script>
  import Stepper from '../../steppers/UploadStepper'
  export default {
    components: {
      formStepper: Stepper,
    },
    props: {
      model: Object,
      update: Boolean,
    },
    created () {

    },
    methods: {
      updateUpload () {
        const vm = this
        vm.$router.replace('/contributor/videos')
        vm.$emit('close')
        vm.$swal({
          title: 'Your content was updated!',
          text: 'You will receive a notification once the content is approved',
          icon: 'success',
        })
      },
    },
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
    .video-js{
        width: 100%;
        height: 400px;
        margin: auto
    }
</style>
